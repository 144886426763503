<template>
  <div class="target-container">
    <StartStop :position="positionStr" :pageInfo="pageInfoObj" />
  </div>
</template>

<script>
import StartStop from "@/components/Course/CoursePage/StartStop";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfoObj: {
        pinyin: "Yī èr sān sì wǔ (shàng)",
        hanzi: "一二三四五（上）",
        english: "1, 2, 3, 4, 5 (I)",
        sound: require("@/assets/audio/L1/1-Lesson/lesson-03-title.mp3")
      },
    };
  },
  components: {
    StartStop,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>