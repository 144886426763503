<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="true" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "我会认",
          isUse: false,
          content: [
            {
              pinyin: "lǎoshī",
              hanzi: "老师",
              symbol: "",
              group: 0,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 1,
              symbolType: 1,
            },
            {
              pinyin: "yī",
              hanzi: "一",
              symbol: "",
              group: 0,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 3,
              symbolType: 1,
            },
            {
              pinyin: "èr",
              hanzi: "二",
              symbol: "",
              group: 0,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 5,
              symbolType: 1,
            },
            {
              pinyin: "sān",
              hanzi: "三",
              symbol: "",
              group: 0,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 7,
              symbolType: 1,
            },
            {
              pinyin: "sì",
              hanzi: "四",
              symbol: "",
              group: 0,
              index: 8,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "、",
              group: 0,
              index: 9,
              symbolType: 1,
            },
            {
              pinyin: "wǔ",
              hanzi: "五",
              symbol: "",
              group: 0,
              index: 10,
              symbolType: 0,
            },

            // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
          ],
        },
        {
          title: "我会用",
          isUse: true,
          contentList: [
            [
              {
                pinyin: "jùzi",
                hanzi: "句子",
                symbol: "",
                group: 1,
                line: 0,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：",
                group: 1,
                line: 0,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "“",
                group: 1,
                line: 0,
                index: 2,
                symbolType: 3,
              },
              {
                pinyin: "Lǎoshī  hǎo!",
                hanzi: "老师好！",
                symbol: "",
                group: 1,
                line: 0,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 0,
                index: 4,
                symbolType: 7,
              },
              {
                pinyin: "de",
                hanzi: "的",
                symbol: "",
                group: 1,
                line: 0,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "shǐyòng",
                hanzi: "使用",
                symbol: "",
                group: 1,
                line: 0,
                index: 6,
                symbolType: 0,
              },
            ],
          ],
        },
        {
          title: "我会说",
          isUse: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "yòng",
              hanzi: "用",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "Zhōngwén",
              hanzi: "中文",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "shǔshù",
              hanzi: "数数",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            // {
            //   pinyin: "",
            //   hanzi: "(",
            //   symbol: "",
            //   group: 2,
            //   index: 3,
            //   symbolType: 7,
            // },
            {
              pinyin: "(yī dào wǔ)",
              hanzi: "(一到五)",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            // {
            //   pinyin: "",
            //   hanzi: ")",
            //   symbol: "",
            //   group: 2,
            //   index: 5,
            //   symbolType: 7,
            // },
            {
              pinyin: "jí",
              hanzi: "及",
              symbol: "",
              group: 2,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "gēn",
              hanzi: "跟",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "lǎoshī",
              hanzi: "老师",
              symbol: "",
              group: 2,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "dǎ zhāohū",
              hanzi: "打招呼",
              symbol: "",
              group: 2,
              index: 7,
              symbolType: 0,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>